@import url(https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap);
* {
  box-sizing: border-box;
}

/* width */
-webkit-scrollbar {
width: 5px;
}

/* Track */
-webkit-scrollbar-track {
background: #222222; 
}
  
/* Handle */
-webkit-scrollbar-thumb{
background: #888; 
}


/* Handle on hover */
-webkit-scrollbar-thumb:hover {
background: #555; 
}

body {
  margin: 0px;
  color: #333;
  --type-first: Helvetica, Arial, sans-serif;
  --type-second: 'Spectral', Georgia;
  font-family: var(--type-first);
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

.container2 {
  max-width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
}

.mainContainer {
  padding-top: 1rem;
}

.title {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 1rem;
  margin: 0;
  position: relative;
  z-index: 1;
}

.title::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 0.5rem;
  background: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 0.2rem;
  z-index: -1;
}

.animeLeft {
  opacity: 0;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  -webkit-animation: animeLeft 0.3s forwards;
          animation: animeLeft 0.3s forwards;
}

@-webkit-keyframes animeLeft {
  to {
    opacity: 1;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes animeLeft {
  to {
    opacity: 1;
    -webkit-transform: initial;
            transform: initial;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh + 10rem);
  /*  background-color: #090b0c; */
}

.AppBody {
  flex: 1 1;
  /*max-width: 99%;
  margin: 0 auto;
  padding: 10px 0;*/
}

.VictoryContainer {
  height: initial !important;
}



.Modal2 {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  overflow: hidden;
  background-color: papayawhip;
  }
  
  .Overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(13, 38, 107, 0.30);
  }
  

  .anterior {
    position: relative;
    }
    
    .area {
    display: flex;
    align-items: center;
    }
    
    .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    }
    
    .Cards {
    display: flex;
    justify-content: center;
    width:100%;
    }
    
    .Card {
    border: 3px solid;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    
    display: flex;
    flex-basis: 25%;
    flex-direction: column;
    box-sizing:border-box;
    }
    
    .Card .Title {
    padding: 5px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;    
    }
    
    .Card .Content {
    flex-grow: 1;
    background-color: #fff;
    color: #000;
    padding: 10px;
    text-align: center;
    }
    
    .slide-in-top {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    
    /* ----------------------------------------------
    * Generated by Animista on 2021-6-26 20:21:15
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info. 
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */
    
    /**
    * ----------------------------------------
    * animation slide-in-top
    * ----------------------------------------
    */
    @-webkit-keyframes slide-in-top {
    0% {
    -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateY(0);
      transform: translateY(0);
    opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
    -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateY(0);
      transform: translateY(0);
    opacity: 1;
    }
    }
    
    .slide-in-fwd-center {
    -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    

    .characters {
      list-style: none;
      padding: 10px;
      margin: 10px;
      overflow-y: scroll;
      height: 70vh;
      width:100%;
    }
    
    .characters li {
      display: flex;
      align-items: center;
      border: solid 2px #d0d0d0;
      border-radius: .2em;
      padding: .5em .8em .5em .5em;
      margin-bottom: 1em;
      overflow: hidden;
    
      border-radius: 10px;
      overflow: hidden;
      margin: 10px;
      position: relative;
      flex-basis: 25%;
      box-sizing:border-box;
    }
    
    .characters p {
      max-width: none;
      font-weight: bold;
      margin: 0;
    }
    
    .characters-thumb {
      overflow: hidden;
      flex-shrink: 0;
      width: 2em;
      height: 2em;
      background-color: #e8e8e8;
      padding: .5em;
      margin-right: .5em;
    }
    
    .characters-thumb img {
      display: block;
      width: 100%;
      height: auto;
    }



.grid {
  display: grid;
  grid-gap: 10px;
  min-height: 100vh;
  grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
}

.gridAdd {
  grid-auto-rows: 20px;
}

/* Non-grid specific CSS */  
body {
	color: #374046;
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 1.15em;
  padding-top: 4rem;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

.item {
  background-color: #ffffff;
  border-radius: 5px;
}

.photothumb{
  width: 100%;
}

.title {
  padding: 20px;
 }

.title h3{
  font-size: 1.2em;
  color: #ffffff;
  text-transform: uppercase;
}

.project .title{
  background-color: #607D8B;
}

.blog .title{
  background-color: #ff9800;
}

.photo .title{
  background-color: #f44336;
}

.desc{
  padding: 10px 10px 5px 10px;
  overflow: hidden;
}

.desc img{
  width: 50%;
  margin: 0 10px 10px 0;
  float: left;
}

.desc p{
  margin-bottom: 10px;
}






/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


.scroll-to-top-x {
  position: fixed;
  z-index: 1042;
  right: 1rem;
  bottom: 1rem;
}

.scroll-to-top-x button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #2c3e5050;
  line-height: 3.1rem;
  padding: 15px;
  margin: -5px;
  border: none;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.scroll-to-top {
  z-index: 1042;
  right: 1rem;
  bottom: 1rem;
  display: none;
}

.scroll-to-top button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #2c3e5050;
  line-height: 3.1rem;
  padding: 15px;
  margin: -5px;
  border: none;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}



























#myTabContenti .active {
  position: relative;
}

#myTabi .nav  { 
  display: grid;   grid-template-columns: repeat(auto-fill,minmax(90px,1fr));
}     

.gareai {
  display:flex; flex-direction: column; align-items: center;
}

.gpanei {     
  display: grid !important; position: absolute; grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
}

.gquadi {
  padding: 10px; margin: 5px; background: #2c3e50; border-radius: 5px; text-align: center; color: #fff; min-height: 100px; min-width: 100px; display: flex; flex-direction: column; align-items: center; justify-content: center;
}

.aquadi:hover {
  text-decoration: none;
  background-color: #4c7095;
  border-radius: 5px;
}

.gtexti {
  font-size: 2em; border-radius: 5px;background-color: #4c7095; padding: 0 10px;
}

.formatoitemi {
  margin-bottom: 10px;
  background-color: #2c3e50;
  padding: 10px;
  border-radius: 5px;
}

#myTabi .nav-link {
  color: #fff;
  margin: 2px;
}

#myTabi .nav-link:hover {
  color: #fff;
  background-color: #0074d9;
}

@media (min-width: 1008px) {


}

@media (min-width: 641px) and (max-width: 1007px) {


}

@media (min-width: 497px) and (max-width: 640px) {

  .formatoi {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
  }

  .gpanei {
      display: none !important;
  }

  .gpanei.active {
      display: flex !important; flex-wrap: wrap; position: absolute; top: 0; left: 0;
  }            

  .formatoitemi {
    width: 100%;
  }

  #myTabContenti .active {
      position: relative;
  }

}

@media (max-width: 496px)
{
  .formatoi {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
  }

  .gquadi {
    
  }

  .gpanei {
      display: none !important;
  }

  .gpanei.active {
      display: flex !important; flex-wrap: wrap; position: absolute; top: 0; left: 0;
  }            

  .formatoitemi {
      max-width: 100%;
  }

  #myTabContenti .active {
      position: relative;
  }

}



.bounce-in-top {
  -webkit-animation: bounce-in-top 1.1s both;
  animation: bounce-in-top 1.1s both;
  }
  
  /* ----------------------------------------------
  * Generated by Animista on 2021-6-26 20:51:29
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */
  
  /**
  * ----------------------------------------
  * animation bounce-in-top
  * ----------------------------------------
  */
  @-webkit-keyframes bounce-in-top {
  0% {
  -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  opacity: 0;
  }
  38% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  opacity: 1;
  }
  55% {
  -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
  -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
  -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  }
  @keyframes bounce-in-top {
  0% {
  -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  opacity: 0;
  }
  38% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  opacity: 1;
  }
  55% {
  -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
  -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
  -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  }
  
  .scale-out-center {
    -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  


.bounce-out-bottom {
	-webkit-animation: bounce-out-bottom 1.5s both;
	        animation: bounce-out-bottom 1.5s both;
}

/**
 * ----------------------------------------
 * animation bounce-out-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(38px);
            transform: translateY(38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px);
            transform: translateY(800px);
    opacity: 0;
  }
}
@keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(38px);
            transform: translateY(38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px);
            transform: translateY(800px);
    opacity: 0;
  }
}





      /* width */
      #lessons::-webkit-scrollbar {
          width: 5px;
          }
  
          /* width */
          #downloads::-webkit-scrollbar {
          width: 5px;
          }
  
          /* width */
          #audios::-webkit-scrollbar {
          width: 5px;
          }
  
          /* Track */
          #lessons::-webkit-scrollbar-track {
          background: #222222; 
          }
  
          /* Track */
          #downloads::-webkit-scrollbar-track {
          background: #222222; 
          }
  
          /* Track */
          #audios::-webkit-scrollbar-track {
          background: #222222; 
          }
          
          /* Handle */
          #lessons::-webkit-scrollbar-thumb{
          background: #888; 
          }
  
          /* Handle */
          #downloads::-webkit-scrollbar-thumb{
          background: #888; 
          }
  
          /* Handle */
          #audios::-webkit-scrollbar-thumb{
          background: #888; 
          }
  
          /* Handle on hover */
          #lessons::-webkit-scrollbar-thumb:hover {
          background: #555; 
          }
  
          /* Handle on hover */
          #downloads::-webkit-scrollbar-thumb:hover {
          background: #555; 
          }
  
          /* Handle on hover */
          #audios::-webkit-scrollbar-thumb:hover {
          background: #555; 
          }
  
          #myTabContent .active {
              position: relative;
          }
  
          .lessons {
              list-style: none;
              margin:0;
              margin-top: 10px;
              margin-left: -10px;
              padding:0;
              width: 100%;
          }
  
          .lessons-item {
              width: 100%;
              padding: 10px;
              padding: 0 10px 10px 10px;
          }
  
          .lessons-item .active {
              background-color: #000;
          }
  
          .lessons-item a {
              background-color: #2c3e50;
              padding: 10px;
              border-radius: 5px;
              width: 100%;
              color: #fff;
          }
  
          .lessons-item a:hover {
              text-decoration: none;
              background-color: #111920;
          }
  
          #myTab .nav  { 
              display: grid;   grid-template-columns: repeat(auto-fill,minmax(90px,1fr));
          }     
  
          .formato {
              display: grid !important; grid-template-columns: 1fr auto;
          }

          .garealesson {
              background-color: #1c252e;
              margin-top: 10px;
              color: #fff;
              padding: 5px;
              text-align: center;
          }            

          .garea {
              background-color: #1c252e;
              min-height: 50vh;
              text-align: center;
          }
  
          .gpane {     
              display: grid !important; position: absolute; grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
          }
          
          .gquad {
              padding: 10px; margin: 5px; background: #2c3e50; border-radius: 5px; text-align: center; color: #fff; min-height: 100px; min-width: 100px; display: flex; flex-direction: column; align-items: center; justify-content: center;
          }
  
          .aquad:hover {
              text-decoration: none;
              background-color: #4c7095;
              border-radius: 5px;
          }
  
          .gtext {
              font-size: 2em; border-radius: 5px;background-color: #4c7095; padding: 0 10px;
          }
  
          .formatoitem {
              margin-bottom: 10px;
              background-color: #2c3e50;
              padding: 10px;
              border-radius: 5px;
          }
  
          #myTab .nav-link {
              color: #fff;
              margin: 2px;
          }
  
          #myTab .nav-link:hover {
              color: #fff;
              background-color: #0074d9;
          }
  
          @media (min-width: 1008px) {
              
          }
          
          @media (min-width: 641px) and (max-width: 1007px) {
  
              .lessons {
              margin-top: 10px;
              }
  
          }
          
          @media (min-width: 497px) and (max-width: 640px) {
  
              .lessons {
              margin: 0;
              }
          
          }
          
          @media (max-width: 496px)
          {
              .lessons {
              margin: 0;
              }
  
          }
  

          .tooltip {
              position: absolute;
              z-index: 1500;
              background: rgba(0,0,0,.6);
              box-shadow: 0 0 0 2px #FB5, 0 0 0 6px rgba(0,0,0,.6);
              padding: .5rem;
              color: white;
              opacity: 1;
              font-weight: bold;
              font-size: .875rem;
              max-width: 170px;
              border-radius: 2px;
              top: 0px;
              left: 0px;
            }            

.lessons-item button.active {
  background-color: #FB5 !important;
}              



.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 340px;
  z-index: 3000;
  }

.Modal {
  position: absolute;
  top: 65px;
  right: 10px;
  bottom: 40px;
  width: 330px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 3000;
  background-color: papayawhip;
  height: calc(100vh - 16em);
  }
  


  .cor1{
    background-color: #D9522B;
  }
  
  .cor2{
    background-color: #2D89EF;
  }
  
  .cor3{
    background-color: #A400AB;
  }
  
  .cor4{
    background-color: #1CB0E2;
  }
  
  .cor5{
    background-color: #6c757d;
  }
  
  .cor6{
    background-color: #008398;
  }
  
  .cor7{
    background-color: #8F0098;
  }
  
  .cor8{
    background-color: #F5F5F5;
  }
  
  .quadimg a {  
    font-size: 1.3em;
  }
  
  .quadimg a img{
      margin-top: -40px;
      max-height: 200px;
  }








  /*
 * CKEditor 5 (v34.1.0) content styles.
 * Generated on Mon, 20 Jun 2022 11:42:49 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
  --ck-color-base-active: hsl(208, 88%, 52%);
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-color-table-column-resizer-hover: var(--ck-color-base-active);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-table-column-resizer-position-offset: calc(
    var(--ck-table-column-resizer-width) * -0.5 - 0.5px
  );
  --ck-table-column-resizer-width: 7px;
  --ck-todo-list-checkmark-size: 16px;
  --ck-z-default: 1;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: hsl(60, 97%, 73%);
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: hsl(120, 93%, 68%);
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: hsl(345, 96%, 73%);
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: hsl(201, 97%, 72%);
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: hsl(0, 85%, 49%);
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: hsl(112, 100%, 27%);
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-image-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - 1.5em);
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: 1.5em;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: calc(1.5em / 2);
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: calc(1.5em / 2);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: calc(1.5em / 2);
  margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: calc(1.5em / 2);
  margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
  font-style: italic;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: 16px;
  width: var(--ck-todo-list-checkmark-size);
  height: 16px;
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(16px / 3);
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(16px / 5.3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(16px / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(16px / 2.6);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(16px / 8)
    calc(16px / 8) 0;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  user-select: none;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
  overflow: hidden;
  table-layout: fixed;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
  position: relative;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .table-column-resizer {
  position: absolute;
  top: -999999px;
  bottom: -999999px;
  right: calc(
    7px * -0.5 - 0.5px
  );
  right: var(--ck-table-column-resizer-position-offset);
  width: 7px;
  width: var(--ck-table-column-resizer-width);
  cursor: col-resize;
  -webkit-user-select: none;
          user-select: none;
  z-index: 1;
  z-index: var(--ck-z-default);
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table[draggable] .table-column-resizer {
  display: none;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .table-column-resizer:hover,
.ck-content .table .table-column-resizer__active {
  background-color: hsl(208, 88%, 52%);
  background-color: var(--ck-color-table-column-resizer-hover);
  opacity: 0.25;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content[dir="rtl"] .table .table-column-resizer {
  left: calc(
    7px * -0.5 - 0.5px
  );
  left: var(--ck-table-column-resizer-position-offset);
  right: unset;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content.ck-read-only .table .table-column-resizer {
  display: none;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-table-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}

/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: hsla(341, 100%, 30%, 0.1);
  background: var(--ck-color-mention-background);
  color: hsl(341, 100%, 30%);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}

.Header_header__uWf6o {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0px;
  background: #f9f9f9;   
}

.Header_nav__2CgsU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.Header_logo__3yjPx {
  padding: 0.5rem 0;
  margin-top: 5px;
}

.Header_login__9uso9 {
  color: #333;
  display: flex;
  align-items: center;
}

.Header_login__9uso9::after {
  content: '';
  display: inline-block;
  width: 14px;
  height: 17px;
  background: url(/static/media/usuario.dc442992.svg) no-repeat center center;
  margin-left: 0.5rem;
  position: relative;
  top: -1px;
}

.pesquisar_wrapper__1XOxK {
  margin: 1rem auto;
  width: 70%;
}

@media (max-width: 60rem) {
  .pesquisar_wrapper__1XOxK {
    width: 50%;
  }
}

@media (max-width: 40rem) {
  .pesquisar_wrapper__1XOxK {
    width: 40%;
  }
}

@media (max-width: 30rem) {
  .pesquisar_wrapper__1XOxK {
    width: 30%;
  }
}

.pesquisar_input__3XVZ6 {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.9rem;
  background: #eee;
  transition: 0.2s;
}

.pesquisar_input__3XVZ6:focus,
.pesquisar_input__3XVZ6:hover {
  outline: none;
  border-color: #39c643;
  background: white;
  box-shadow: 0 0 0 3px #fea;
}

.pesquisar_label__aEK69 {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.pesquisar_error__2i3eM {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.search_wrapper__NzGhE {
  margin: 1rem auto;
  width: 100%;
  position: relative;
}

.search_wrapper__NzGhE .search_input__c3Vaw {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.9rem;
  background: #eee;
  transition: 0.2s;

  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  background: #f1f1f1;
  z-index: 10;  
}

/* Style the submit button */
.search_wrapper__NzGhE button {
  position: absolute;
  right: 5px;
  top: 4px;
  padding: 0 10px;
  color: #000;
  border: none;
  border-radius: 50%;
  height: 40px;
  font-size: 17px;
  cursor: pointer;
  background: none;
  z-index: 100;
}

.search_wrapper__NzGhE button:hover {
  color: #f2f2f2;
}


.search_input__c3Vaw:focus,
.search_input__c3Vaw:hover {
  outline: none;
  border-color: #39c643;
  background: #f2f2f2;
  box-shadow: 0 0 0 3px #fea;
}

.search_label__1OYNe {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.search_error__2_d9e {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.Footer_footer__1iVil {
  background: rgb(0 102 162);
  background-color: rgb(9 11 12);
  text-align: center;
  color: #fff;
  padding: 20px 0;
}

.Footer_footer__1iVil p {
  margin-top: 1rem;
}

.Footer_banner__3ycNz {
  background-color: rgb(52 58 64);
  text-align: center;
  color: #fff;
  padding: 10px 0;
}

.Footer_banner__3ycNz a:link {
  color: #fff;
}

.Footer_banner__3ycNz a:visited {
  color: #fff;
}

.Footer_banner__3ycNz a:active {
  color: #fff;
}

.Footer_banner__3ycNz a:hover {
  color: #ff0;
}

.Feed_perfil__2nG_7 {
  background: rgb(249 249 249);
  padding: 10px;
  margin: 0 10px 10px 0;
  text-align: center;
}

.Feed_outside__3m-3o {
  display: flex;
  overflow: hidden;
  border-radius: 10px;
}

@media (max-width: 40rem) {
  .Feed_outside__3m-3o {
    display: block;
  }
}

.FeedModal_modal__3R6YO {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 2000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 0 10px 10px 0;
}

@media (max-width: 40rem) {
  .FeedModal_modal__3R6YO {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}

.Loading_wrapper__1nM4_ {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.Loading_loading__M8Nus {
  margin: auto;
  width: 80px;
  height: 80px;
  display: flex;
  padding-left: 5px;
  background: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.PhotoContent_photo__1QRqO {
  margin: 0 auto;
  height: 80vh;
  width: auto;
  background: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  opacity: 0;
  text-align: center;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-animation: PhotoContent_scaleUp__2sZez 0.3s forwards;
          animation: PhotoContent_scaleUp__2sZez 0.3s forwards;
  transition: width 1s;
}

.PhotoContent_single__1pQoi.PhotoContent_photo__1QRqO {
  grid-template-columns: 1fr;
  height: auto;
}

@-webkit-keyframes PhotoContent_scaleUp__2sZez {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes PhotoContent_scaleUp__2sZez {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

.PhotoContent_details__3ql2W {
  margin-bottom: 10px; 
  overflow-y: auto; 
  padding: 15px;
}

.PhotoContent_mag__38uUd {
  height: 100% !important;
  width: auto !important;
}

.PhotoContent_single__1pQoi .PhotoContent_details__3ql2W {
  padding: 1rem 0px 0px 0px;
}

.PhotoContent_img__3o8nD {
  grid-row: 1/4;
}

.PhotoContent_single__1pQoi .PhotoContent_img__3o8nD {
  grid-row: 1;
  border-radius: 0.4rem;
  overflow: hidden;
}

.PhotoContent_land__3OBY- {
  grid-template-columns: 1fr;
  grid-template-rows: 80vh;
  -webkit-animation: PhotoContent_exampleUp__2afL5 0.3s forwards;
          animation: PhotoContent_exampleUp__2afL5 0.3s forwards;
}

@-webkit-keyframes PhotoContent_exampleUp__2afL5 {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes PhotoContent_exampleUp__2afL5 {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

.PhotoContent_land__3OBY- .PhotoContent_img__3o8nD {
  grid-row: 1;
}

@media (max-width: 992px) {
  .PhotoContent_photo__1QRqO {
    grid-template-columns: 1fr;
    grid-template-rows: 50vh;
  }
  .PhotoContent_img__3o8nD {
    grid-row: 1;
  }
}

.PhotoContent_author__1DXJ4 {
  opacity: 0.5;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PhotoContent_author__1DXJ4 a:hover {
  text-decoration: underline;
}

.PhotoContent_visualizacoes__9Tt4i::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 0.5rem;
  background: url(/static/media/visualizacao-black.957befc3.svg);
}

.PhotoContent_likes__1hAo9::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 0.5rem;
  background: url(/static/media/hand.f91cd378.svg);
}

.PhotoContent_attributes__1dNNx {
  display: flex;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.PhotoContent_attributes__1dNNx li {
  margin-right: 2rem;
}

.PhotoContent_attributes__1dNNx li::before {
  content: '';
  display: inline-block;
  height: 20px;
  margin-right: 0.5rem;
  position: relative;
  top: 3px;
  width: 2px;
  background: #333;
  margin-top: 5px;
}

.PhotoCommentsForm_form__2ZLAH {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: stretch;
  margin: 1rem;
}

.PhotoCommentsForm_single__3Asfq.PhotoCommentsForm_form__2ZLAH {
  margin: 1rem 0;
}

.PhotoCommentsForm_textarea__1A-md {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-family: var(--type-first);
  resize: none;
  border: 1px solid #eee;
  padding: 0.5rem;
  border-radius: 0.2rem;
  background: #eee;
  transition: 0.2s;
}

.PhotoCommentsForm_textarea__1A-md:focus,
.PhotoCommentsForm_textarea__1A-md:hover {
  outline: none;
  border-color: #fb1;
  background: white;
  box-shadow: 0 0 0 3px #fea;
}

.PhotoCommentsForm_button__3506V {
  border: none;
  cursor: pointer;
  color: #333;
  background: transparent;
  font-size: 1rem;
  padding: 0 1rem;
  overflow: hidden;
}

.PhotoCommentsForm_button__3506V:focus,
.PhotoCommentsForm_button__3506V:hover {
  outline: none;
}

.PhotoCommentsForm_button__3506V:focus svg path,
.PhotoCommentsForm_button__3506V:hover svg path {
  fill: #fea;
  stroke: #fb1;
}

.PhotoCommentsForm_button__3506V:focus svg g,
.PhotoCommentsForm_button__3506V:hover svg g {
  -webkit-animation: PhotoCommentsForm_latir__spyoW 0.6s infinite;
          animation: PhotoCommentsForm_latir__spyoW 0.6s infinite;
}

@-webkit-keyframes PhotoCommentsForm_latir__spyoW {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes PhotoCommentsForm_latir__spyoW {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.PhotoComments_comments___ClkF {
  overflow-y: auto;
  word-break: break-word;
  padding: 0 2rem;
  list-style: none;
  text-align: left;
}

.PhotoComments_single__ooZA4.PhotoComments_comments___ClkF {
  padding: 0px;
}

.PhotoComments_comments___ClkF ul {
  list-style: none;
  text-align: left;
}

.PhotoComments_comments___ClkF li {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}


.PhotoDelete_delete__15EJO {
  background: #ddd;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  transition: 0.1s;
}

.PhotoDelete_delete__15EJO:focus,
.PhotoDelete_delete__15EJO:hover {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
}

.PhotoUpdate_update__3b6hS {
  background: #ddd;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  transition: 0.1s;
}

.PhotoUpdate_update__3b6hS:focus,
.PhotoUpdate_update__3b6hS:hover {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
}

.Image_wrapper__28gIx {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;  
}

.Image_img__nmHgn {
  display: block;
  max-width: 100%;
  grid-area: 1/1;
  opacity: 0;
  transition: 0.2s;
}

.Image_skeleton__38Y23 {
  grid-area: 1/1;
  height: 100%;
  background-image: linear-gradient(90deg, #eee 0px, #fff 50%, #eee 100%);
  background-color: #eee;
  background-size: 200%;
  -webkit-animation: Image_skeleton__38Y23 1.5s infinite linear;
          animation: Image_skeleton__38Y23 1.5s infinite linear;
}

@-webkit-keyframes Image_skeleton__38Y23 {
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
}

@keyframes Image_skeleton__38Y23 {
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
}

.FeedPhotosItem_photo2__1ADd9:nth-child(2) {
  grid-column: 2 / 4;
  grid-row: span 2;
}

@media (max-width: 40rem) {
  .FeedPhotosItem_photo2__1ADd9:nth-child(2) {
    grid-column: initial;
    grid-row: initial;
  }
}

.FeedPhotosItem_photo__3R_l7 {
  display: grid;
  border-radius: 0.2rem;
  overflow: hidden;
  cursor: pointer;
}

.FeedPhotosItem_photo__3R_l7 > div {
  grid-area: 1/1;
}

.FeedPhotosItem_visualizacao__3C6Qy {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  grid-area: 1/1;
  display: none;
}

.FeedPhotosItem_visualizacao__3C6Qy::before {
  width: 16px;
  height: 10px;
  content: '';
  display: inline-block;
  margin-right: 0.25rem;
  background: url(/static/media/visualizacao.4ce15ed8.svg) no-repeat;
}

.FeedPhotosItem_photo__3R_l7:hover .FeedPhotosItem_visualizacao__3C6Qy {
  display: flex;
}

.FeedPhotos_feed__17KJw {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 1rem;
  justify-items: center;
}

@media (max-width: 80rem) {
  .FeedPhotos_feed__17KJw {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 60rem) {
  .FeedPhotos_feed__17KJw {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 50rem) {
  .FeedPhotos_feed__17KJw {
    grid-template-columns: repeat(1, 1fr);
  }
}

.PhotoContentMax_photo__1SrsE {
  margin: 0 auto;
  height: 80vh;
  width: auto;
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  opacity: 0;
  text-align: center;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-animation: PhotoContentMax_scaleUp__37HQB 0.3s forwards;
          animation: PhotoContentMax_scaleUp__37HQB 0.3s forwards;
  transition: width 1s;
}

.PhotoContentMax_single__3tB4I.PhotoContentMax_photo__1SrsE {
  grid-template-columns: 1fr;
  height: auto;
}

@-webkit-keyframes PhotoContentMax_scaleUp__37HQB {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes PhotoContentMax_scaleUp__37HQB {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

.PhotoContentMax_details__AdNww {
  margin-bottom: 10px; 
  overflow-y: auto; 
  padding: 15px;
}

.PhotoContentMax_mag__3Rl9q {
  height: 100% !important;
  width: auto !important;
}

.PhotoContentMax_single__3tB4I .PhotoContentMax_details__AdNww {
  padding: 1rem 0px 0px 0px;
}

.PhotoContentMax_img__3mnKW {
  grid-row: 1/4;
}

.PhotoContentMax_single__3tB4I .PhotoContentMax_img__3mnKW {
  grid-row: 1;
  border-radius: 0.4rem;
  overflow: hidden;
}

.PhotoContentMax_land__3lgme {
  grid-template-columns: 1fr;
  grid-template-rows: 50vh;
  overflow-y: auto;
  -webkit-animation: PhotoContentMax_exampleUp__WikcE 0.3s forwards;
          animation: PhotoContentMax_exampleUp__WikcE 0.3s forwards;
}

@-webkit-keyframes PhotoContentMax_exampleUp__WikcE {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes PhotoContentMax_exampleUp__WikcE {
  to {
    opacity: initial;
    -webkit-transform: initial;
            transform: initial;
  }
}

.PhotoContentMax_land__3lgme .PhotoContentMax_img__3mnKW {
  grid-row: 1;
}

@media (max-width: 992px) {
  .PhotoContentMax_photo__1SrsE {
    grid-template-columns: 1fr;
    grid-template-rows: 50vh;
  }
  .PhotoContentMax_img__3mnKW {
    grid-row: 1;
  }
}

.PhotoContentMax_author__3HlSy {
  opacity: 0.5;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PhotoContentMax_author__3HlSy a:hover {
  text-decoration: underline;
}

.PhotoContentMax_visualizacoes__2NLn5::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 0.5rem;
  background: url(/static/media/visualizacao-black.957befc3.svg);
}

.PhotoContentMax_likes__Z_xux::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 0.5rem;
  background: url(/static/media/hand.f91cd378.svg);
}

.PhotoContentMax_attributes__2Q4J2 {
  display: flex;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.PhotoContentMax_attributes__2Q4J2 li {
  margin-right: 2rem;
}

.PhotoContentMax_attributes__2Q4J2 li::before {
  content: '';
  display: inline-block;
  height: 20px;
  margin-right: 0.5rem;
  position: relative;
  top: 3px;
  width: 2px;
  background: #333;
  margin-top: 5px;
}

.Input_wrapper__2HrvW {
  margin-bottom: 1rem;
}

.Input_input__2kWcf {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.Input_input__2kWcf:focus,
.Input_input__2kWcf:hover {
  outline: none;
  border-color: #39c643;
  background: white;
  box-shadow: 0 0 0 3px #fea;
}

.Input_label__3P1Ma {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.Input_error__306hH {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.Button_button__2DhS- {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #39c643;
  color: #FFF;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
}

.Button_button__2DhS-:hover,
.Button_button__2DhS-:focus {
  outline: none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px #39c643;
}

.Button_button__2DhS-:disabled {
  opacity: 0.5;
  cursor: wait;
}

.LoginForm_form__23abd {
  margin-bottom: 2rem;
}

.LoginForm_perdeu__1PKBW {
  display: inline-block;
  color: #666;
  padding: 0.5rem 0;
  line-height: 1;
}

.LoginForm_perdeu__1PKBW::after {
  content: '';
  height: 2px;
  width: 100%;
  background: currentColor;
  display: block;
}

.LoginForm_cadastro__37b4Z {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.LoginForm_cadastro__37b4Z p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.LoginForm_subtitle__2UqWV {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 2rem;
}

.LoginForm_subtitle__2UqWV::after {
  content: '';
  display: block;
  background: #ddd;
  height: 0.5rem;
  width: 3rem;
  border-radius: 0.2rem;
}

.Login_login__2NQEK {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  grid-gap: 2rem;
  gap: 2rem;
}

.Login_login__2NQEK::before {
  display: block;
  content: '';
  background: url(/static/media/login.4f9505d4.webp) no-repeat center center;
  background-size: cover;
}

.Login_forms__36aZJ {
  max-width: 30rem;
  padding: 1rem;
  display: flex;
  align-items: center;
}

@media (max-width: 40rem) {
  .Login_login__2NQEK {
    grid-template-columns: 1fr;
  }
  .Login_login__2NQEK::before {
    display: none;
  }
  .Login_forms__36aZJ {
    max-width: 100%;
  }
}

.UserHeaderNav_nav__K_S6L {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}

.UserHeaderNav_nav__K_S6L a,
.UserHeaderNav_nav__K_S6L button {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.1s;
  cursor: pointer;
}

.UserHeaderNav_nav__K_S6L a:hover,
.UserHeaderNav_nav__K_S6L a:focus,
.UserHeaderNav_nav__K_S6L button:hover,
.UserHeaderNav_nav__K_S6L button:focus {
  background: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
  outline: none;
}

.UserHeaderNav_nav__K_S6L a.active {
  background: white;
  box-shadow: 0 0 0 3px #fea;
  border-color: #fb1;
}

.UserHeaderNav_nav__K_S6L a.active svg > * {
  fill: #fb1;
}

.UserHeaderNav_mobileButton__3NHIf {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.1s;
  cursor: pointer;
}

.UserHeaderNav_mobileButton__3NHIf::after {
  content: '';
  display: block;
  width: 1.2rem;
  height: 2px;
  border-radius: 2px;
  background: currentColor;
  box-shadow: 0 6px currentColor, 0 -6px currentColor;
  transition: 0.2s;
}

.UserHeaderNav_mobileButton__3NHIf:focus,
.UserHeaderNav_mobileButton__3NHIf:hover,
.UserHeaderNav_mobileButtonActive__2amqZ {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px #fea;
  border-color: #fb1;
  color: #fb1;
}

.UserHeaderNav_mobileButtonActive__2amqZ::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}

.UserHeaderNav_navMobile__1vCeV {
  display: block;
  position: absolute;
  top: 70px;
  right: 0px;
  padding: 0 1rem;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
  opacity: 0;
  pointer-events: none;
}

.UserHeaderNav_navMobileActive__3E2nr {
  transition: 0.3s;
  -webkit-transform: initial;
          transform: initial;
  opacity: 1;
  pointer-events: initial;
  z-index: 100;
}

.UserHeaderNav_navMobile__1vCeV a,
.UserHeaderNav_navMobile__1vCeV button {
  display: flex;
  align-items: center;
  background: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  padding: 0.5rem 0;
  cursor: pointer;
}

.UserHeaderNav_navMobile__1vCeV a:hover svg > *,
.UserHeaderNav_navMobile__1vCeV button:hover svg > * {
  fill: #fb1;
}

.UserHeaderNav_navMobile__1vCeV button {
  border-bottom: none;
}

.UserHeaderNav_navMobile__1vCeV svg {
  margin-right: 0.5rem;
}

.UserHeader_header__OnuvD {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  position: relative;
}

.UserPhotoPost_photoPost__1y-wL {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 40rem) {
  .UserPhotoPost_photoPost__1y-wL {
    grid-template-columns: 1fr;
  }
}

.UserPhotoPost_file__o3rRo {
  margin-bottom: 1rem;
}

.UserPhotoPost_preview__2vZAl {
  border-radius: 1rem;
  background-size: cover;
  background-position: center center;
}

.UserPhotoPost_preview__2vZAl::after {
  content: '';
  display: block;
  height: 0px;
  padding-bottom: 100%;
}

.UserPhotoPost_modal__3Oift {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 2000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 0 10px 10px 0;
}

@media (max-width: 40rem) {
  .UserPhotoPost_modal__3Oift {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}

.select_wrapper__2qZex {
  margin-bottom: 1rem;
}

.select_input__3xK_t {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.select_input__3xK_t:focus,
.select_input__3xK_t:hover {
  outline: none;
  border-color: #39c643;
  background: white;
  box-shadow: 0 0 0 3px #fea;
}

.select_label__3plu5 {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.select_error__hVKfS {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.Login_label__3mzpg,
.Login_input__1TRXV,
.Login_button__1N2yL {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
}

.Login_label__3mzpg {
  display: block;
  margin-bottom: 0.25rem;
}

.Login_input__1TRXV {
  border: 1px solid;
  border-radius: 3px;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  display: block;
}

.Login_button__1N2yL {
  color: white;
  background: black;
  border: none;
  padding: 0.7rem 2rem;
  margin-top: 0.5rem;
  border-radius: 3px;
  cursor: pointer;
}

.PhotosContent_list__DcnYe {
  padding: 0px;
  margin: 0px;
  list-style: none;
  position: relative;
}

.PhotosContent_item__h9uNU {
  display: grid;
  grid-template-columns: 50px 1fr auto auto auto auto;
  grid-template-rows: 50px;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.PhotosContent_img__A9C_C {
  max-width: 100%;
  border-radius: 4px;
}

.PhotosContent_title__3GwRy {
  font-size: 1rem;
  font-weight: normal;
  margin: 0px;
}

.PhotosContent_acessos__1a13U {
  font-family: monospace;
  font-size: 0.875rem;
  color: #999;
}

.PhotosContent_modalitem__3hQlb {
  height: 200px;
  width: 200px;
  padding: 2em;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 200px);
  background-color: #FFF;
  z-index: 10;
  border-radius: 10px;
  transition: all 0.3s;
  box-sizing: border-box;
  border: 1px solid #39c643;
}

.PhotosContent_fechar__28Cum {
  height: 10px;
  width: 10px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  display: inline-table;
  cursor: pointer;
  z-index: 15;
}


.PhotosContent_modal__32mqS {
  position: fixed;
  width: 90vw;
  height: 90vh;
  background: #fff;
  display: flex;
  border-radius: 10px;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 50px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow-y: scroll;
}

@media (max-width: 40rem) {
  .PhotosContent_modal__32mqS {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}


.PhotosContent_outside__2LJOU {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 100;
}

@media (max-width: 40rem) {
  .PhotosContent_outside__2LJOU {
    display: block;
  }
}
.UserPhotoEdit_photoPost__2FK4o {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 992px) {
  .UserPhotoEdit_photoPost__2FK4o {
    grid-template-columns: 1fr;
  }
}

.UserPhotoEdit_file__3ah0Q {
  margin-bottom: 1rem;
}

.UserPhotoEdit_preview__3Uu8T {
  border-radius: 1rem;
  background-size: cover;
  background-position: center center;
}

.UserPhotoEdit_preview__3Uu8T::after {
  content: '';
  display: block;
  height: 0px;
  padding-bottom: 100%;
}

.UserPhotoEdit_modalk__1dQNG {
  position: fixed;
  width: 90vw;
  height: 90vh;
  background: #fff;
  display: flex;
  border-radius: 10px;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 50px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow-y: scroll;
}

@media (max-width: 40rem) {
  .UserPhotoEdit_modalk__1dQNG {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}


.UserPhotoEdit_modalx__3U5Wc {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 2000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 0 10px 10px 0;
}

@media (max-width: 40rem) {
  .UserPhotoEdit_modalx__3U5Wc {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}



.PhotosLoadMore_button__1ksv6 {
  margin: 1rem 0 0 auto;
  display: block;
  width: 32px;
  height: 32px;
  background: black;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  font-family: Roboto, sans-serif;
  border: none;
}

.Photos_formulariosearch__1qM-u {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
}

.Header_header__3WZSg {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.Header_title__2Tdqd {
  font-size: 1rem;
  margin: 0px;
}

.Header_login__2juny {
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  display: block;
  background: white;
  width: 8px;
  height: 8px;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 50%;
}

.Header_login__2juny.Header_loading__3Phzv {
  background: orange;
}

.Header_login__2juny.Header_loaded__3-oNo {
  background: greenyellow;
}

.Courses_asidex__2PEv7 {
  width: 350px !important;
}

@media (max-width: 40rem) {
  .Courses_asidex__2PEv7 {
    width: 100% !important;
  }
}
.FeedModalEdit_modal__26l7C {
  position: fixed;
  width: 90vw;
  height: 90vh;
  background: #fff;
  display: flex;
  border-radius: 10px;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 50px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow-y: scroll;
}

@media (max-width: 40rem) {
  .FeedModalEdit_modal__26l7C {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}


.FeedModalEdit_outside__3E7OI {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  margin-top: -200px;
}

@media (max-width: 40rem) {
  .FeedModalEdit_outside__3E7OI {
    display: block;
  }
}
.Login_label__2tOYu,
.Login_input__3Wk2G,
.Login_button__ImaPY {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
}

.Login_label__2tOYu {
  display: block;
  margin-bottom: 0.25rem;
}

.Login_input__3Wk2G {
  border: 1px solid;
  border-radius: 3px;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  display: block;
}

.Login_button__ImaPY {
  color: white;
  background: black;
  border: none;
  padding: 0.7rem 2rem;
  margin-top: 0.5rem;
  border-radius: 3px;
  cursor: pointer;
}

.PhotosContent_list__2ncqY {
  padding: 0px;
  margin: 0px;
  list-style: none;
  position: relative;
}

.PhotosContent_item__1BLd7 {
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.PhotosContent_img__2iz0o {
  max-height: 100px;
  border-radius: 4px;
  width: auto;
}

.PhotosContent_title__1Ed0X {
  font-size: 1rem;
  font-weight: normal;
  margin: 0px;
}

.PhotosContent_acessos__2O0PV {
  font-family: monospace;
  font-size: 0.875rem;
  color: #999;
}

.PhotosContent_modalitem__1iyaM {
  height: 200px;
  width: 200px;
  padding: 2em;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 200px);
  background-color: #FFF;
  z-index: 10;
  border-radius: 10px;
  transition: all 0.3s;
  box-sizing: border-box;
  border: 1px solid #39c643;
}

.PhotosContent_fechar__3uFeq {
  height: 10px;
  width: 10px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  display: inline-table;
  cursor: pointer;
  z-index: 15;
}


.PhotosContent_modal__32u_V {
  position: fixed;
  width: 90vw;
  height: 90vh;
  background: #fff;
  display: flex;
  border-radius: 10px;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 50px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow-y: scroll;
}

@media (max-width: 40rem) {
  .PhotosContent_modal__32u_V {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}


.PhotosContent_outside__3oFfy {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 100;
}

@media (max-width: 40rem) {
  .PhotosContent_outside__3oFfy {
    display: block;
  }
}
.PhotosLoadMore_button__1Gnmq {
  margin: 1rem 0 0 auto;
  display: block;
  width: 32px;
  height: 32px;
  background: black;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  font-family: Roboto, sans-serif;
  border: none;
}

.Photos_formulariosearch__2hA4q {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
}

.Header_header__rsxkU {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.Header_title__1iQCq {
  font-size: 1rem;
  margin: 0px;
}

.Header_login__38hqK {
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  display: block;
  background: white;
  width: 8px;
  height: 8px;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 50%;
}

.Header_login__38hqK.Header_loading__NjPPM {
  background: orange;
}

.Header_login__38hqK.Header_loaded__DjHUC {
  background: greenyellow;
}

