@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap');

* {
  box-sizing: border-box;
}

/* width */
-webkit-scrollbar {
width: 5px;
}

/* Track */
-webkit-scrollbar-track {
background: #222222; 
}
  
/* Handle */
-webkit-scrollbar-thumb{
background: #888; 
}


/* Handle on hover */
-webkit-scrollbar-thumb:hover {
background: #555; 
}

body {
  margin: 0px;
  color: #333;
  --type-first: Helvetica, Arial, sans-serif;
  --type-second: 'Spectral', Georgia;
  font-family: var(--type-first);
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

.container2 {
  max-width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
}

.mainContainer {
  padding-top: 1rem;
}

.title {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 1rem;
  margin: 0;
  position: relative;
  z-index: 1;
}

.title::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 0.5rem;
  background: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 0.2rem;
  z-index: -1;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh + 10rem);
  /*  background-color: #090b0c; */
}

.AppBody {
  flex: 1;
  /*max-width: 99%;
  margin: 0 auto;
  padding: 10px 0;*/
}

.VictoryContainer {
  height: initial !important;
}



.Modal2 {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  overflow: hidden;
  background-color: papayawhip;
  }
  
  .Overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(13, 38, 107, 0.30);
  }
  

  .anterior {
    position: relative;
    }
    
    .area {
    display: flex;
    align-items: center;
    }
    
    .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    }
    
    .Cards {
    display: flex;
    justify-content: center;
    width:100%;
    }
    
    .Card {
    border: 3px solid;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    
    display: flex;
    flex-basis: 25%;
    flex-direction: column;
    box-sizing:border-box;
    }
    
    .Card .Title {
    padding: 5px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;    
    }
    
    .Card .Content {
    flex-grow: 1;
    background-color: #fff;
    color: #000;
    padding: 10px;
    text-align: center;
    }
    
    .slide-in-top {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    
    /* ----------------------------------------------
    * Generated by Animista on 2021-6-26 20:21:15
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info. 
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */
    
    /**
    * ----------------------------------------
    * animation slide-in-top
    * ----------------------------------------
    */
    @-webkit-keyframes slide-in-top {
    0% {
    -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateY(0);
      transform: translateY(0);
    opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
    -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateY(0);
      transform: translateY(0);
    opacity: 1;
    }
    }
    
    .slide-in-fwd-center {
    -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    

    .characters {
      list-style: none;
      padding: 10px;
      margin: 10px;
      overflow-y: scroll;
      height: 70vh;
      width:100%;
    }
    
    .characters li {
      display: flex;
      align-items: center;
      border: solid 2px #d0d0d0;
      border-radius: .2em;
      padding: .5em .8em .5em .5em;
      margin-bottom: 1em;
      overflow: hidden;
    
      border-radius: 10px;
      overflow: hidden;
      margin: 10px;
      position: relative;
      flex-basis: 25%;
      box-sizing:border-box;
    }
    
    .characters p {
      max-width: none;
      font-weight: bold;
      margin: 0;
    }
    
    .characters-thumb {
      overflow: hidden;
      flex-shrink: 0;
      width: 2em;
      height: 2em;
      background-color: #e8e8e8;
      padding: .5em;
      margin-right: .5em;
    }
    
    .characters-thumb img {
      display: block;
      width: 100%;
      height: auto;
    }



.grid {
  display: grid;
  grid-gap: 10px;
  min-height: 100vh;
  grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
}

.gridAdd {
  grid-auto-rows: 20px;
}

/* Non-grid specific CSS */  
body {
	color: #374046;
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 1.15em;
  padding-top: 4rem;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

.item {
  background-color: #ffffff;
  border-radius: 5px;
}

.photothumb{
  width: 100%;
}

.title {
  padding: 20px;
 }

.title h3{
  font-size: 1.2em;
  color: #ffffff;
  text-transform: uppercase;
}

.project .title{
  background-color: #607D8B;
}

.blog .title{
  background-color: #ff9800;
}

.photo .title{
  background-color: #f44336;
}

.desc{
  padding: 10px 10px 5px 10px;
  overflow: hidden;
}

.desc img{
  width: 50%;
  margin: 0 10px 10px 0;
  float: left;
}

.desc p{
  margin-bottom: 10px;
}






/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


.scroll-to-top-x {
  position: fixed;
  z-index: 1042;
  right: 1rem;
  bottom: 1rem;
}

.scroll-to-top-x button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #2c3e5050;
  line-height: 3.1rem;
  padding: 15px;
  margin: -5px;
  border: none;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.scroll-to-top {
  z-index: 1042;
  right: 1rem;
  bottom: 1rem;
  display: none;
}

.scroll-to-top button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #2c3e5050;
  line-height: 3.1rem;
  padding: 15px;
  margin: -5px;
  border: none;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}



























#myTabContenti .active {
  position: relative;
}

#myTabi .nav  { 
  display: grid;   grid-template-columns: repeat(auto-fill,minmax(90px,1fr));
}     

.gareai {
  display:flex; flex-direction: column; align-items: center;
}

.gpanei {     
  display: grid !important; position: absolute; grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
}

.gquadi {
  padding: 10px; margin: 5px; background: #2c3e50; border-radius: 5px; text-align: center; color: #fff; min-height: 100px; min-width: 100px; display: flex; flex-direction: column; align-items: center; justify-content: center;
}

.aquadi:hover {
  text-decoration: none;
  background-color: #4c7095;
  border-radius: 5px;
}

.gtexti {
  font-size: 2em; border-radius: 5px;background-color: #4c7095; padding: 0 10px;
}

.formatoitemi {
  margin-bottom: 10px;
  background-color: #2c3e50;
  padding: 10px;
  border-radius: 5px;
}

#myTabi .nav-link {
  color: #fff;
  margin: 2px;
}

#myTabi .nav-link:hover {
  color: #fff;
  background-color: #0074d9;
}

@media (min-width: 1008px) {


}

@media (min-width: 641px) and (max-width: 1007px) {


}

@media (min-width: 497px) and (max-width: 640px) {

  .formatoi {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
  }

  .gpanei {
      display: none !important;
  }

  .gpanei.active {
      display: flex !important; flex-wrap: wrap; position: absolute; top: 0; left: 0;
  }            

  .formatoitemi {
    width: 100%;
  }

  #myTabContenti .active {
      position: relative;
  }

}

@media (max-width: 496px)
{
  .formatoi {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
  }

  .gquadi {
    
  }

  .gpanei {
      display: none !important;
  }

  .gpanei.active {
      display: flex !important; flex-wrap: wrap; position: absolute; top: 0; left: 0;
  }            

  .formatoitemi {
      max-width: 100%;
  }

  #myTabContenti .active {
      position: relative;
  }

}



.bounce-in-top {
  -webkit-animation: bounce-in-top 1.1s both;
  animation: bounce-in-top 1.1s both;
  }
  
  /* ----------------------------------------------
  * Generated by Animista on 2021-6-26 20:51:29
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */
  
  /**
  * ----------------------------------------
  * animation bounce-in-top
  * ----------------------------------------
  */
  @-webkit-keyframes bounce-in-top {
  0% {
  -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  opacity: 0;
  }
  38% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  opacity: 1;
  }
  55% {
  -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
  -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
  -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  }
  @keyframes bounce-in-top {
  0% {
  -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  opacity: 0;
  }
  38% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  opacity: 1;
  }
  55% {
  -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
  -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
  -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
  -webkit-transform: translateY(0);
    transform: translateY(0);
  -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  }
  
  .scale-out-center {
    -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  


.bounce-out-bottom {
	-webkit-animation: bounce-out-bottom 1.5s both;
	        animation: bounce-out-bottom 1.5s both;
}

/**
 * ----------------------------------------
 * animation bounce-out-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(38px);
            transform: translateY(38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px);
            transform: translateY(800px);
    opacity: 0;
  }
}
@keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(38px);
            transform: translateY(38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px);
            transform: translateY(800px);
    opacity: 0;
  }
}





      /* width */
      #lessons::-webkit-scrollbar {
          width: 5px;
          }
  
          /* width */
          #downloads::-webkit-scrollbar {
          width: 5px;
          }
  
          /* width */
          #audios::-webkit-scrollbar {
          width: 5px;
          }
  
          /* Track */
          #lessons::-webkit-scrollbar-track {
          background: #222222; 
          }
  
          /* Track */
          #downloads::-webkit-scrollbar-track {
          background: #222222; 
          }
  
          /* Track */
          #audios::-webkit-scrollbar-track {
          background: #222222; 
          }
          
          /* Handle */
          #lessons::-webkit-scrollbar-thumb{
          background: #888; 
          }
  
          /* Handle */
          #downloads::-webkit-scrollbar-thumb{
          background: #888; 
          }
  
          /* Handle */
          #audios::-webkit-scrollbar-thumb{
          background: #888; 
          }
  
          /* Handle on hover */
          #lessons::-webkit-scrollbar-thumb:hover {
          background: #555; 
          }
  
          /* Handle on hover */
          #downloads::-webkit-scrollbar-thumb:hover {
          background: #555; 
          }
  
          /* Handle on hover */
          #audios::-webkit-scrollbar-thumb:hover {
          background: #555; 
          }
  
          #myTabContent .active {
              position: relative;
          }
  
          .lessons {
              list-style: none;
              margin:0;
              margin-top: 10px;
              margin-left: -10px;
              padding:0;
              width: 100%;
          }
  
          .lessons-item {
              width: 100%;
              padding: 10px;
              padding: 0 10px 10px 10px;
          }
  
          .lessons-item .active {
              background-color: #000;
          }
  
          .lessons-item a {
              background-color: #2c3e50;
              padding: 10px;
              border-radius: 5px;
              width: 100%;
              color: #fff;
          }
  
          .lessons-item a:hover {
              text-decoration: none;
              background-color: #111920;
          }
  
          #myTab .nav  { 
              display: grid;   grid-template-columns: repeat(auto-fill,minmax(90px,1fr));
          }     
  
          .formato {
              display: grid !important; grid-template-columns: 1fr auto;
          }

          .garealesson {
              background-color: #1c252e;
              margin-top: 10px;
              color: #fff;
              padding: 5px;
              text-align: center;
          }            

          .garea {
              background-color: #1c252e;
              min-height: 50vh;
              text-align: center;
          }
  
          .gpane {     
              display: grid !important; position: absolute; grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
          }
          
          .gquad {
              padding: 10px; margin: 5px; background: #2c3e50; border-radius: 5px; text-align: center; color: #fff; min-height: 100px; min-width: 100px; display: flex; flex-direction: column; align-items: center; justify-content: center;
          }
  
          .aquad:hover {
              text-decoration: none;
              background-color: #4c7095;
              border-radius: 5px;
          }
  
          .gtext {
              font-size: 2em; border-radius: 5px;background-color: #4c7095; padding: 0 10px;
          }
  
          .formatoitem {
              margin-bottom: 10px;
              background-color: #2c3e50;
              padding: 10px;
              border-radius: 5px;
          }
  
          #myTab .nav-link {
              color: #fff;
              margin: 2px;
          }
  
          #myTab .nav-link:hover {
              color: #fff;
              background-color: #0074d9;
          }
  
          @media (min-width: 1008px) {
              
          }
          
          @media (min-width: 641px) and (max-width: 1007px) {
  
              .lessons {
              margin-top: 10px;
              }
  
          }
          
          @media (min-width: 497px) and (max-width: 640px) {
  
              .lessons {
              margin: 0;
              }
          
          }
          
          @media (max-width: 496px)
          {
              .lessons {
              margin: 0;
              }
  
          }
  

          .tooltip {
              position: absolute;
              z-index: 1500;
              background: rgba(0,0,0,.6);
              box-shadow: 0 0 0 2px #FB5, 0 0 0 6px rgba(0,0,0,.6);
              padding: .5rem;
              color: white;
              opacity: 1;
              font-weight: bold;
              font-size: .875rem;
              max-width: 170px;
              border-radius: 2px;
              top: 0px;
              left: 0px;
            }            

.lessons-item button.active {
  background-color: #FB5 !important;
}              



.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 340px;
  z-index: 3000;
  }

.Modal {
  position: absolute;
  top: 65px;
  right: 10px;
  bottom: 40px;
  width: 330px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 3000;
  background-color: papayawhip;
  height: calc(100vh - 16em);
  }
  


  .cor1{
    background-color: #D9522B;
  }
  
  .cor2{
    background-color: #2D89EF;
  }
  
  .cor3{
    background-color: #A400AB;
  }
  
  .cor4{
    background-color: #1CB0E2;
  }
  
  .cor5{
    background-color: #6c757d;
  }
  
  .cor6{
    background-color: #008398;
  }
  
  .cor7{
    background-color: #8F0098;
  }
  
  .cor8{
    background-color: #F5F5F5;
  }
  
  .quadimg a {  
    font-size: 1.3em;
  }
  
  .quadimg a img{
      margin-top: -40px;
      max-height: 200px;
  }








  /*
 * CKEditor 5 (v34.1.0) content styles.
 * Generated on Mon, 20 Jun 2022 11:42:49 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
  --ck-color-base-active: hsl(208, 88%, 52%);
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-color-table-column-resizer-hover: var(--ck-color-base-active);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-table-column-resizer-position-offset: calc(
    var(--ck-table-column-resizer-width) * -0.5 - 0.5px
  );
  --ck-table-column-resizer-width: 7px;
  --ck-todo-list-checkmark-size: 16px;
  --ck-z-default: 1;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: var(--ck-color-image-caption-text);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
  font-style: italic;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
  overflow: hidden;
  table-layout: fixed;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
  position: relative;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .table-column-resizer {
  position: absolute;
  top: -999999px;
  bottom: -999999px;
  right: var(--ck-table-column-resizer-position-offset);
  width: var(--ck-table-column-resizer-width);
  cursor: col-resize;
  user-select: none;
  z-index: var(--ck-z-default);
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table[draggable] .table-column-resizer {
  display: none;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .table-column-resizer:hover,
.ck-content .table .table-column-resizer__active {
  background-color: var(--ck-color-table-column-resizer-hover);
  opacity: 0.25;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content[dir="rtl"] .table .table-column-resizer {
  left: var(--ck-table-column-resizer-position-offset);
  right: unset;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content.ck-read-only .table .table-column-resizer {
  display: none;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-table-caption-text);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}

/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}
