.photo {
  margin: 0 auto;
  height: 80vh;
  width: auto;
  background: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  opacity: 0;
  text-align: center;
  transform: scale(0.8);
  animation: scaleUp 0.3s forwards;
  transition: width 1s;
}

.single.photo {
  grid-template-columns: 1fr;
  height: auto;
}

@keyframes scaleUp {
  to {
    opacity: initial;
    transform: initial;
  }
}

.details {
  margin-bottom: 10px; 
  overflow-y: auto; 
  padding: 15px;
}

.mag {
  height: 100% !important;
  width: auto !important;
}

.single .details {
  padding: 1rem 0px 0px 0px;
}

.img {
  grid-row: 1/4;
}

.single .img {
  grid-row: 1;
  border-radius: 0.4rem;
  overflow: hidden;
}

.land {
  grid-template-columns: 1fr;
  grid-template-rows: 80vh;
  animation: exampleUp 0.3s forwards;
}

@keyframes exampleUp {
  to {
    opacity: initial;
    transform: initial;
  }
}

.land .img {
  grid-row: 1;
}

@media (max-width: 992px) {
  .photo {
    grid-template-columns: 1fr;
    grid-template-rows: 50vh;
  }
  .img {
    grid-row: 1;
  }
}

.author {
  opacity: 0.5;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author a:hover {
  text-decoration: underline;
}

.visualizacoes::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 0.5rem;
  background: url('../../Assets/visualizacao-black.svg');
}

.likes::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 0.5rem;
  background: url('../../Assets/hand.svg');
}

.attributes {
  display: flex;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.attributes li {
  margin-right: 2rem;
}

.attributes li::before {
  content: '';
  display: inline-block;
  height: 20px;
  margin-right: 0.5rem;
  position: relative;
  top: 3px;
  width: 2px;
  background: #333;
  margin-top: 5px;
}
